<template>
  <v-container fluid>
    <data-protection-content></data-protection-content>
  </v-container>
</template>

<script>
import DataProtectionContent from "@/components/DataProtectionContent"
export default {
  name: "Imprint",
  components:{DataProtectionContent}
};
</script>

<style >

</style>